import React from "react";

function Footer() {
  return (
    <>
      <div className="footer">
        <span>&copy; Copyright {new Date().getFullYear()}</span>
      </div>
    </>
  );
}

export default Footer;
