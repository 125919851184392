const portfolio = [
  {
    id: 1,
    key: 1,
    image: "https://i.imgur.com/1g2ezFh.png",
    title: "Password Generator",
    description: "Generate a random password based on the criteria selected.",
    tech: "HTML, CSS, JavaScript, Font Awesome",
    github: "https://github.com/ambertrand/PW-Generator",
    deployed: "https://ambertrand.github.io/PW-Generator/",
  },
  {
    id: 2,
    key: 2,
    image: "https://i.imgur.com/YBIBT19.png",
    title: "Insect Game",
    description: "Test your skills at squashing all the bugs!",
    tech: "HTML, CSS, JavaScript",
    github: "https://github.com/ambertrand/Insect-Game",
    deployed: "https://ambertrand.github.io/Insect-Game/",
  },
  {
    id: 3,
    key: 3,
    image: "https://i.imgur.com/uu7ZMWj.png",
    title: "Todo List",
    description: "Make a todo list, cross off and delete items once completed",
    tech: "HTML, CSS, JavaScript",
    github: "https://github.com/ambertrand/Todo-List",
    deployed: "https://ambertrand.github.io/Todo-List/",
  },
  {
    id: 4,
    key: 4,
    image: "https://i.imgur.com/V1z1Ftj.png",
    title: "Quiz App",
    description: "Test your knowledge at some coding questions",
    tech: "HTML, CSS, JavaScript",
    github: "https://github.com/ambertrand/Quiz-App",
    deployed: "https://ambertrand.github.io/Quiz-App/",
  },
  {
    id: 5,
    key: 5,
    image: "https://i.imgur.com/liWvzNJ.png",
    title: "Weather Dashboard",
    description:
      "A weather dashboard to search for cities and view current and 5-day forecast data.",
    tech: "HTML, CSS, Javascript, Bootstrap, JQuery, Moment.js, Openweather API",
    github: "https://github.com/ambertrand/Weather-Dashboard",
    deployed: "https://ambertrand.github.io/Weather-Dashboard/",
  },
  // {
  //   id: 6,
  //   key: 6,
  //   image: "https://i.imgur.com/MMxvj1s.png",
  //   title: "While You're There",
  //   description:
  //     "Have you ever gone shopping for someone and forgot something?  We definitely have!  Now with Covid-19 it is more important than ever to remember shopping items in one trip.  Which is exactly what brought us to the idea for While You're There.",
  //   tech: "React, React-Bootstrap, Auth0, FontAwesome, Socket.io, NodeJS, MySQL, Sequelize, Express, nodemon, Balsamiq, Trello",
  //   github: "https://github.com/ambertrand/While-Youre-There",
  //   deployed: "https://while-youre-there.herokuapp.com/",
  // },
];

export default portfolio;
